<template>
  <monitor-template
    ref="monitorRef"
    :mapPageParams="mapPageParams"
    @mapPageClick="handleMapPageClick"
    @mapToolClick="handleMapToolClick"
  >
    <div slot="left">
      <!-- 左侧弹窗 -->
      <!-- 固定宽度体验更佳 -->
      <vxe-modal
        v-model="showLeftModal"
        title="车辆监控中心"
        :width="modalParam.width"
        height="100%"
        class="my-modal"
        @close="handleCloseLeftModal"
        show-zoom
        resize
        :lock-view="false"
        :mask="false"
        :position="{ top: '0%', left: '8%' }"
        @show="handleModalShow"
      >
        <template>
          <!-- 树形结构:车辆数据 -->
          <div class="vechicle-center">
            <object-tree
              :style="activeKey === '2' ? 'height:33%' : 'height:50%'"
              ref="vehicleTree"
              :tree-data="treeData"
              place-holder="输入车牌或车辆自编号进行搜索"
              object-title="自动驾驶车辆"
              cache-key="vehicleTree"
              object-icon="vehicleAutoIcon"
              :tree-selected-key="currentSelectedId"
              :init-selected-key="initSelectedKey"
              @select="handleSelectVehicle"
              @check="handleCheckVehicle"
              @filter="handleFilterTreeData"
              :onlineImgSrc="onlineImgSrc"
              :offlineImgSrc="offlineImgSrc"
              onlineHint="该车当前在线"
              offlineHint="该车当前离线"
              showFilterBtn
            />

            <a-divider style="height: 1px; margin: 5px 0" />
            <!-- 操作按钮区 -->
            <a-row type="flex" justify="space-around">
              <VehicleFuncList
                ref="funcListRef"
                :objectInfo="newObjectInfo"
                :objectPoints="monitorObjectPoints"
                :modalParam="modalParam"
                :taskStatusOptions="taskStatusOptions"
                :currentCheckedList="currentCheckedList"
                :showLeftModal="showLeftModal"
                @onVehicleFuncEvent="handleVehicleFuncEvent"
                :currentSelectedId="currentSelectedId"
                :isGettingVehicleInfo="isGettingVehicleInfo"
                :isTrackTab="isTrackTab"
                @onGetVehicleInfo="handleGetVehicleInfo"
              />
              <!-- @onUpdateMap="mapPageParams.isUpdateMap = $event" -->
            </a-row>
            <a-divider style="height: 1px; margin: 5px 0" />
            <a-row>
              <a-col :span="24">
                <a-tabs v-model="activeKey" type="card" size="small" @change="handleTabChange">
                  <a-tab-pane key="1" tab="实时状态">
                    <MapVehicleInfoWindow
                      :map-obj="MapObj"
                      :objectInfo="newObjectInfo"
                      :taskStatusOptions="taskStatusOptions"
                    />
                    <!-- <div>wsTimes：{{ wsCount }}，updateCount: {{ updateCount }}，timerCount: {{ timerCount }}</div>
                    <div>startTime: {{ startTime }}</div>
                    <div>wsTime: {{ recentTime }}, ws cost time：{{ totalTime }}</div> -->
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="历史轨迹">
                    <!-- 传入monitorRef是为了操作地图 -->
                    <map-history-track
                      ref="historyTrackRef"
                      :monitorRef="$refs.monitorRef"
                      :objectInfo="newObjectInfo"
                      :params="mapPageParams"
                      :initTrackDate="initTrackDate"
                      :historyTrackPointsArray="mapPageParams.historyTrackPointsArray"
                      :taskStatusOptions="taskStatusOptions"
                      @onHistoryTrackSearch="handleHistoryTrackSearch"
                      :isGettingVehicleInfo="isGettingVehicleInfo"
                      @onTrackChange="handleTrackChange"
                      @onTrackJumpTo="handleTrackJumpTo"
                    />
                  </a-tab-pane>
                  <a-tab-pane key="3" tab="硬件健康" v-if="false" disabled> </a-tab-pane>
                  <a-tab-pane key="4" tab="异常记录" v-if="false" disabled> </a-tab-pane>
                  <a-tab-pane key="5" tab="重要事件">
                    <tab-table
                      :currentSelectedId="currentSelectedId"
                      :list="vehicleEventlist"
                      showEventType
                      @toHistoryTrack="toHistoryTrack"
                      @toVehicleEvent="toVehicleEvent"
                    ></tab-table>
                  </a-tab-pane>
                  <a-tab-pane key="6" tab="急停记录">
                    <tab-table
                      :currentSelectedId="currentSelectedId"
                      :list="vehicleEventlist"
                      activeKey="4"
                      @toHistoryTrack="toHistoryTrack"
                    ></tab-table>
                  </a-tab-pane>
                </a-tabs>
              </a-col>
            </a-row>
          </div>
        </template>
      </vxe-modal>
    </div>
  </monitor-template>
</template>
<script>
import { mapState } from 'vuex'
import {
  getVehicleTree,
  getHistoricalTrack,
  getOnlineVehicleList,
  getAllVehicleInfo,
  getOneVehicleInfo
} from '@/api/iot/vehicle'
import MonitorTemplate from '../../monitorTemplate'
import MapHistoryTrack from '../../monitorComponents/mapHistoryTrack'
// 车辆树
import ObjectTree from '../../monitorComponents/objectTree'
import MapVehicleInfoWindow from '../../monitorComponents/mapInfoWindow/vehicle/object'
import { Tabs } from 'ant-design-vue'
import coordinateTransform from '@/utils/coordinateTransform'
import VehicleFuncList from './funcList'
import tabTable from '@/views/monitor/monitorComponents/tabTable'
import { listEventRecord } from '@/api/iot/eventRecord'
import { VehiclePlayIcon } from '@/views/monitor/monitorComponents/utils/mapUtil'
import { buildOnlineTreeData, setOnlineFlag } from '@/views/monitor/utils/treeHelper'

export default {
  name: 'IndexVirtual',
  components: {
    MonitorTemplate,
    ObjectTree,
    MapHistoryTrack,
    MapVehicleInfoWindow,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    VehicleFuncList,
    tabTable
  },
  data() {
    return {
      // UI是否及时更新
      // objectDelayUpdateInterVal默认配置为0
      // 此配置控制实时状态数据更新
      objectDelayUpdateInterVal: 0,
      mapPageParams: {
        // 是否更新地图，当打开其它窗口时，则不更新地图
        isUpdateMap: true,
        showHeader: false,
        mapDelayUpdate: true,
        // 以下场景内存占用大约在500M以下
        // 当objectDelayUpdateInterVal=0 && mapDelayUpdateInterval=0，支持100辆车
        // 当objectDelayUpdateInterVal=500 && mapDelayUpdateInterval=0，支持200辆车
        // 当objectDelayUpdateInterVal=0 && mapDelayUpdateInterval=1000,支持200辆车
        // 当objectDelayUpdateInterVal=0 && mapDelayUpdateInterval=2000,支持300辆车
        // 当objectDelayUpdateInterVal=0 && mapDelayUpdateInterval=0，200辆车，1分钟内就会出现页面延迟渲染
        // 当objectDelayUpdateInterVal=0 && mapDelayUpdateInterval=1000,300辆车，1分钟内就会出现页面延迟渲染
        // mapDelayUpdateInterval默认配置为1000，默认配置支持200辆车
        // 此配置控制车辆在地图上的实时分布
        mapDelayUpdateInterval: 1000,
        objectId: 'vehicleId',
        infoWindowTitle: 'vehicleId',
        // 显示轨迹明细按钮
        showMapTrackDetailButton: false,
        moduleKey: 'vehicle',
        // 地图数据源
        mapDataSource: 'real',
        // 地图上左侧按钮显示文字
        leftModalTitle: '车辆监控中心',
        // 地图上右侧按钮显示文字
        rightModalTitle: '',
        // 车辆显示图标
        objectIcon: { url: require('@/assets/images/map/vehicle-sweeper.png'), size: { width: 80, height: 60 } },
        // 车辆离线图标
        objectIconOff: { url: require('@/assets/images/map/vehicle-off.png'), size: { width: 80, height: 60 } },
        // 保洁人员当前位置分布及相关人员信息
        objectPoints: [],
        historyTrackPointsArray: [],
        // 路线规划
        laneDataArray: [],
        lushuConfig: {
          playIcon: VehiclePlayIcon,
          // 不需要控制车辆方向
          enableRotation: true,
          // 轨迹播放时ICON上方显示的文字
          defaultContent: '',
          // 图标大小
          iconSize: { width: 52, height: 26 },
          // 图标的定位点相对于图标左上角的偏移值
          anchorSize: { width: 27, height: 14 }
        },
        // 是否显示地图轨迹明细
        showMapTrackDetail: false,
        // 当前选中的轨迹对象
        currentTrackObject: null,
        // 正在连接车辆中...
        loadingWebsocket: false,
        loadingWebsocketMsg: '正在连线车辆，请稍后...',
        // 被选中的车移动距离（当前位置距离上一次更新的位置距离）超过多少米后才能移动位置并重置弹窗
        // 但弹窗信息必须实时更新
        selectedObjectMoveDistanceForResetLocation: 2
      },
      showLeftModal: true,
      treeData: [],
      // 当前选中的设备Id
      currentSelectedId: '',
      // 当前打勾的设备列表
      currentCheckedList: [],
      // 人员状态信息
      cleanerInfoArray: [],
      // 当前选中的IMEI列表
      selectedImeiArray: [],
      currentActiveTabKey: '1',
      // 当前地图坐标对应的地址
      currentMapAddress: '',
      // 当前选中的物体对象
      objectInfo: null,
      newObjectInfo: null,
      taskStatusOptions: [],
      eventOptions: [],
      activeKey: '1',
      vehicleEventlist: [],
      // 最新对象节点
      recentObjectPointsObj: { isUpdate: true },
      // 更新timer
      updateTimer: null,
      // 地图对象
      MapObj: null,
      // 最新对象节点，非地图使用
      realObjectPoints: [],
      // 定时器是否处理完毕
      isTimerDone: true,
      // ws更新次数
      wsCount: 0,
      // 定时器执行次数
      timerCount: 0,
      // 实际更新次数
      updateCount: 0,
      // ws接收的最新时间
      recentTime: '',
      // 开始时间
      startTime: '',
      totalTime: 0,
      wsStartTime: 0,
      isFromTrackToReal: false,
      modalParam: {
        width: 390,
        left: 0
      },
      // 实时监控对象列表
      monitorObjectPoints: [],
      // 初始化轨迹日期
      initTrackDate: '',
      onlineImgSrc: require('@/assets/images/vehicle-online.png'),
      offlineImgSrc: require('@/assets/images/vehicle-offline.png'),
      isAll: true,
      onlineList: [],
      // 车辆在线状态Map
      vehicleOnlineMap: new Map(),
      // 是否重新订阅WS
      isReSubscribeWs: false,
      // 选中的车辆列表最后变更时间
      checkedListLastChangedTime: 0,
      // 是否正在获取车辆信息
      isGettingVehicleInfo: false,
      // 是否正在播放轨迹视频
      isSyncVideo: false,
      bindVehicleIdMap: []
    }
  },
  computed: {
    ...mapState({
      allInfo: (state) => {
        console.log('allInfo', state)
        const info = state.websocket.allInfo
        for (const item of info) {
          if (item.virtualVehicle === 1) {
            if (!item.location) {
              item.location = {}
            }
            item.location.latitude = item.lat
            item.location.longitude = item.lng
            item.location.heading = item.virtualHeading
            for (const bItem of info) {
              if (bItem.vehicleId === item.bindVehicleId) {
                for (const key in bItem) {
                  if (
                    key !== 'vehicleId' &&
                    key !== 'name' &&
                    key !== 'lng' &&
                    key !== 'lat' &&
                    key !== 'id' &&
                    key !== 'licensePlateNum' &&
                    key !== 'virtualVehicle' &&
                    key !== 'isSelect' &&
                    key !== 'isSelectVirtualVehicle' &&
                    key !== 'bindVehicleId' &&
                    key !== 'ins'
                  ) {
                    if (key !== 'location') {
                      item[key] = bItem[key]
                    } else {
                      for (const lKey in bItem[key]) {
                        if (lKey !== 'latitude' && lKey !== 'longitude' && lKey !== 'heading') {
                          item.location[lKey] = bItem.location[lKey]
                        }
                      }
                    }
                  }
                }
                break
              }
            }
          }
        }
        // let updateData = {}
        // if (state.websocket.isSelectVirtualVehicle) {
        //   // info = state.websocket.virtualVehicleList
        //   for (const item of info) {
        //     if (state.websocket.selectVirtualObj && item.vehicleId === state.websocket.selectVirtualObj.bindVehicleId) {
        //       updateData = item
        //       break
        //     }
        //   }
        //   for (const item of info) {
        //     if (state.websocket.selectVirtualObj && item.vehicleId === state.websocket.selectVirtualObj.vehicleId) {
        //       for (const key in updateData) {
        //         if (
        //           key !== 'vehicleId' &&
        //           key !== 'name' &&
        //           key !== 'lng' &&
        //           key !== 'lat' &&
        //           key !== 'id' &&
        //           key !== 'licensePlateNum' &&
        //           key !== 'virtualVehicle' &&
        //           key !== 'isSelect' &&
        //           key !== 'isSelectVirtualVehicle' &&
        //           key !== 'bindVehicleId'
        //         ) {
        //           item[key] = updateData[key]
        //         }
        //       }
        //       if (!item.location) {
        //         item.location = {}
        //       }
        //       item.location.latitude = state.websocket.selectVirtualObj.lat
        //       item.location.longitude = state.websocket.selectVirtualObj.lng
        //       if (item.ins) {
        //         item.ins.latitude = state.websocket.selectVirtualObj.lat
        //         item.ins.longitude = state.websocket.selectVirtualObj.lng
        //       }
        //       break
        //     }
        //   }
        // }
        console.log('info:', info)
        return info
      },
      websocketReady: (state) => state.websocket.websocketReady,
      allInfoReady: (state) => state.websocket.allInfoReady
    }),
    isTrackTab() {
      console.log('isPlayingTrackVideo')
      return this.currentActiveTabKey === '2'
    }
  },
  watch: {
    currentCheckedList(newVal, oldVal) {
      if (newVal && newVal.length === 0) {
        this.mapPageParams.loadingWebsocket = false
        this.isGettingVehicleInfo = false
      } else {
        this.mapPageParams.loadingWebsocket = true
        this.isGettingVehicleInfo = true
      }
      // 数据更新
      if (this.mapPageParams.mapDataSource === 'real') {
        this.toUpdateMap()
      }

      // 如何降低订阅频率？
      this.checkedListLastChangedTime = new Date().getTime()
      this.isReSubscribeWs = true

      // setTimeout(() => {
      //   // 当选中的车辆列表发生变化时，发送WS请求
      //   this.disconnectSocket()
      //   if (newVal.length > 0) {
      //     this.loadVehiclesMap(newVal)
      //   }
      // }, 300)
    },
    activeKey(newVal, oldVal) {
      // 轨迹播放切换到实时状态
      if (newVal === '1' && oldVal === '2') {
        // 更新一次数据
        this.isFromTrackToReal = true
        // 如果没有选中的车辆
        if (this.currentCheckedList.length === 0) {
          this.toUpdateMap()
        }
      }
      if (oldVal === '2') {
        this.$refs.historyTrackRef && this.$refs.historyTrackRef.cancelSyncVideo()
      }
    },
    // ws返回的最新车辆列表
    allInfo(newVal, oldVal) {
      const tmpArr = newVal.map((item) => {
        let lng = 0
        let lat = 0
        if (item.location && item.location.longitude > 0 && item.location.latitude > 0) {
          lng = item.location.longitude
          lat = item.location.latitude
        } else if (item.ins) {
          lng = item.ins.longitude
          lat = item.ins.latitude
        }
        // if (lng > 0 && lat > 0) {
        try {
          const coo = coordinateTransform.wgs84togcj02tobd09(lng, lat)
          return { ...item, ...coo, rotation: item.location ? item.location.heading : 0 }
        } catch (error) {
          return { ...item, lng: 0, lat: 0 }
        }
        // } else {
        //   return { ...item, lng: 0, lat: 0 }
        // }
      })
      for (let k = tmpArr.length - 1; k >= 0; k--) {
        if (this.currentCheckedList.indexOf(tmpArr[k].vehicleId) < 0) {
          tmpArr.splice(k, 1)
        }
      }
      this.recentObjectPointsObj.isUpdate = false
      // 地图更新
      this.recentObjectPointsObj.isMapUpdate = false
      this.recentObjectPointsObj.data = tmpArr
      if (tmpArr.length > 0) {
        this.recentTime = tmpArr[0].time
      }
      if (!this.startTime) {
        this.startTime = this.recentTime
      }
      if (this.wsStartTime === 0) {
        this.wsStartTime = new Date().getTime()
      }
      this.totalTime = (new Date().getTime() - this.wsStartTime) / 1000
      ++this.wsCount
    },
    allInfoReady(newVal, oldVal) {
      console.log('allInfoReady', newVal)
      this.mapPageParams.loadingWebsocket = false
    },
    currentSelectedId(newVal, oldVal) {
      console.log('newVal', newVal)
      console.log('oldVal', oldVal)
      console.log('this.currentActiveTabKey', this.currentActiveTabKey)
      if (newVal && newVal !== oldVal) {
        console.log('this.currentActiveTabKey', this.currentActiveTabKey)
        if (this.currentActiveTabKey === '5') {
          this.vehicleEventlist = []
          this.listEventRecord()
        } else if (this.currentActiveTabKey === '6') {
          this.vehicleEventlist = []
          this.listEventRecord('4')
        }
      }
      // 切换车辆后上一次轨迹数据需要清空
      if (oldVal && newVal && newVal !== oldVal && this.mapPageParams.mapDataSource === 'track') {
        if (this.mapPageParams.historyTrackPointsArray && this.mapPageParams.historyTrackPointsArray.length > 0) {
          this.mapPageParams.historyTrackPointsArray = []
        }
      }
    },
    objectInfo(newVal, oldVal) {
      console.log('objectInfo newVal', newVal)
      this.newObjectInfo = newVal
    }
  },
  created() {
    this.setInitSelectedKey()
    this.initVehicleTree()
    this.getData()
    this.mapPageParams.loadingWebsocket = false
    this.isGettingVehicleInfo = false
  },
  mounted() {
    this.initTimer()
  },
  beforeDestroy() {
    this.updateTimer && clearInterval(this.updateTimer)
    this.disconnectSocket()
  },
  methods: {
    // 关闭其它的弹窗
    closeOtherModal() {
      this.$refs.funcListRef.closeOtherModal()
    },
    handleTrackChange(trackIndex, trackTime) {
      console.log(`当前轨迹信息,索引=${trackIndex},时间=${trackTime}`)
    },
    handleTrackJumpTo(trackIndex, trackTime, status) {
      console.log(`轨迹跳转到,索引=${trackIndex},时间=${trackTime}`)
    },
    handleGetVehicleInfo(ids) {
      this.subscribeManyVehicle(ids, (data) => {
        // 通知打开监控页面
        this.$refs.funcListRef.setManyMonitorObjects(data)
      })
    },
    // 过滤数据
    handleFilterTreeData(isAll) {
      this.isAll = isAll
      const data = JSON.parse(JSON.stringify(this.allTreeData))
      if (this.isAll) {
        // 如果显示全部车辆
        // 更新车辆树
        setOnlineFlag(data, this.vehicleOnlineMap)
        this.treeData = [...data]
        // 视图更新
        this.recentObjectPointsObj.isUpdate = false
        this.recentObjectPointsObj.isMapUpdate = false
      } else {
        getOnlineVehicleList({}).then((res) => {
          console.log('res', res)
          this.onlineList = res.data.map((p) => 't-1-' + p.vehicleId)
          // 如果只显示在线的车辆
          buildOnlineTreeData(data, this.onlineList)
          this.vehicleOnlineMap.clear()
          this.onlineList.forEach((p) => {
            this.vehicleOnlineMap.set(p, 1)
          })
          this.treeData = data
          // 视图更新
          // this.recentObjectPointsObj.isUpdate = false
          // this.recentObjectPointsObj.isMapUpdate = false
        })
      }
    },

    setInitSelectedKey() {
      if (this.$route.query.type === 'track') {
        this.initSelectedKey = this.$route.query.vehicleId
        this.activeKey = '2'
        this.mapPageParams.mapDataSource = 'track'
        this.mapPageParams.showHeader = true
        this.mapPageParams.showMapTrackDetailButton = true
        this.initTrackDate = this.$route.query.date
      }
    },
    handleModalShow(e) {
      this.modalParam.left = e.$modal.getPosition().left
      console.log('left', this.modalParam)
    },
    toUpdateMap() {
      const data = this.recentObjectPointsObj.data
      if (data && data.length > 0) {
        for (let k = data.length - 1; k >= 0; k--) {
          if (this.currentCheckedList.indexOf(data[k].vehicleId) < 0) {
            data.splice(k, 1)
          }
        }
        this.recentObjectPointsObj.data = data
        this.recentObjectPointsObj.isUpdate = false
        this.recentObjectPointsObj.isMapUpdate = false
      }
    },
    initTimer() {
      this.updateTimer = setInterval(() => {
        this.updateRecentObjectPoints()
        if (this.isReSubscribeWs && this.checkedListLastChangedTime > 0) {
          const second = new Date().getTime() - this.checkedListLastChangedTime
          if (Math.floor(second / 1000) >= 2) {
            this.isReSubscribeWs = false
            this.checkedListLastChangedTime = 0
            this.disconnectSocket()
            const currentCheckedList = this.currentCheckedList
            if (currentCheckedList.length > 0) {
              this.loadVehiclesMap(currentCheckedList)
            }
          }
        }
      }, this.objectDelayUpdateInterVal)
    },
    setMonitorObjectPoints() {
      const data = this.recentObjectPointsObj.data
      if (data) {
        const objectArray = data.slice(0, 3)
        if (objectArray.length === 0) {
          this.monitorObjectPoints = []
        } else if (this.monitorObjectPoints.length === 0) {
          this.monitorObjectPoints = [...objectArray]
        } else {
          const monitorObjectPoints = this.monitorObjectPoints
          // 移除当前不存在的车辆,已存在则更新
          const n = monitorObjectPoints.length
          for (let k = n - 1; k >= 0; k--) {
            const obj = objectArray.find((t) => t.vehicleId === monitorObjectPoints[k].vehicleId)
            if (!obj) {
              monitorObjectPoints.splice(k, 1)
            } else {
              monitorObjectPoints[k] = Object.assign(monitorObjectPoints[k], obj)
            }
          }

          // 加入新的车辆
          if (monitorObjectPoints.length < objectArray.length) {
            objectArray.forEach((p) => {
              const obj = monitorObjectPoints.find((t) => t.vehicleId === p.vehicleId)
              if (!obj) {
                this.monitorObjectPoints.push(p)
              }
            })
          }
        }
      }
      // 内存泄漏1： 以下代码每次全新对象赋值会引起内存泄漏
      // this.monitorObjectPoints = this.recentObjectPointsObj.data
    },
    updateRecentObjectPoints() {
      // 判断任务是否执行完成
      ++this.timerCount
      if (this.isTimerDone) {
        this.isTimerDone = false
        try {
          // 如果打开其它窗口，则地图节点分布与当前选中车辆的位置也不再更新
          if (this.mapPageParams.isUpdateMap === true) {
            // 允许地图更新状态
            if (this.mapPageParams.mapDataSource === 'real' && this.recentObjectPointsObj.isMapUpdate === false) {
              this.recentObjectPointsObj.isMapUpdate = true
              if (this.isFromTrackToReal) {
                // 如果是从轨迹播放页切换回实时状态页，则要求延迟处理地图刷新
                // 避免在地图切换过程中更新数据，导致地图渲染有问题
                setTimeout(() => {
                  this.doUpdate()
                }, 100)
              } else {
                this.doUpdate()
              }
              this.setMonitorObjectPoints()
              ++this.updateCount
            }
          } else {
            // 地图状态不允许更新
            if (this.mapPageParams.mapDataSource === 'real' && this.recentObjectPointsObj.isUpdate === false) {
              this.recentObjectPointsObj.isUpdate = true
              this.toSelectObject()
              ++this.updateCount
              this.setMonitorObjectPoints()
            }
          }

          // 处理直接跳转到轨迹页
          if (this.initSelectedKey && this.mapPageParams.mapDataSource === 'track') {
            if (this.recentObjectPointsObj.isUpdate === false) {
              if (!this.mapPageParams.currentTrackObject) {
                this.toSelectObject()
              }
            }
          } else if (this.mapPageParams.mapDataSource === 'track') {
            this.setTrackObjectInfo()
          }
        } finally {
          this.isTimerDone = true
        }
      }
    },
    doUpdate() {
      this.toSelectObject()
      const data = this.recentObjectPointsObj.data
      if (!data) {
        return
      }
      // 更新车辆树在线状态，并判断是否有车辆状态更新
      let isUpdateTree = false
      for (let k = 0, n = data.length; k < n; k++) {
        const id = 't-1-' + data[k].vehicleId
        const online = data[k].online ? 1 : 0
        const lastOnline = this.vehicleOnlineMap.get(id)
        if (online !== lastOnline) {
          isUpdateTree = true
          this.vehicleOnlineMap.set(id, online)
        }
      }

      if (isUpdateTree) {
        // 更新车辆树
        const treeData = [...this.treeData]
        setOnlineFlag(treeData, this.vehicleOnlineMap)
        this.treeData = treeData
      }
      console.log('this.currentCheckedList', this.currentCheckedList)

      // 设置地图上的车辆位置
      this.$refs.monitorRef && this.$refs.monitorRef.mapUpdateObjectPoints(data)
    },
    disconnectSocket() {
      this.websocketReady = false
      this.$store.dispatch('stopWebSocket')
      this.loadingWebsocket = false
      this.isGettingVehicleInfo = false
    },
    async loadVehiclesMap(ids) {
      if (!this.websocketReady) {
        // 建立socket连接
        await this.$store.dispatch('websocketConnect')
      }
      if (ids) {
        console.log('http获取所有节点数据', this.allInfo)
        if (!this.allInfoReady) {
          this.mapPageParams.loadingWebsocket = true
          this.isGettingVehicleInfo = true
        }
        const params = {
          vehicleIds: ids.join(','),
          selectVehicleId: this.currentSelectedId
        }
        const wIds = JSON.parse(JSON.stringify(ids))
        getAllVehicleInfo(params).then(async (res) => {
          // 通过ws请求数据
          console.log(`http获取所有节点数据成功vehicleIds=${this.ids}`)
          this.mapPageParams.loadingWebsocket = false
          this.isGettingVehicleInfo = false
          let obj = {}
          obj = JSON.parse(JSON.stringify(res.data))
          if (obj.list) {
            for (const item of obj.list) {
              if (item.virtualVehicle === 1) {
                // item.vehicleId = item.bindVehicleId
                if (!item.location) {
                  item.location = {}
                }
                item.withAllInfo = true
                item.location.latitude = item.lat
                item.location.longitude = item.lng
                if (item.ins) {
                  item.ins.latitude = item.lat
                  item.ins.longitude = item.lng
                }
                if (!item.name || !item.licensePlateNum) {
                  const virtualInfo = await this.getVirtualInfo(item.vehicleId)
                  item.name = virtualInfo.name
                  item.licensePlateNum = virtualInfo.licensePlateNum
                }
                this.bindVehicleIdMap[item.vehicleId] = item.bindVehicleId
                if (wIds.indexOf(item.bindVehicleId) === -1) {
                  wIds.push(item.bindVehicleId)
                }
              }
            }
            this.virtualVehicleList = obj.list
          }

          if (obj.select) {
            if (obj.select.virtualVehicle === 1) {
              // obj.select.vehicleId = obj.select.bindVehicleId
              this.isSelectVirtualVehicle = true
              this.selectVirtualObj = obj.select
              console.log('setVirtual obj select', obj.select)
              this.$store.dispatch('setVirtual', {
                isSelectVirtualVehicle: true,
                selectVirtualObj: obj.select
              })
              if (!obj.select.location) {
                obj.select.location = {}
              }
              obj.select.location.latitude = obj.select.lat
              obj.select.location.longitude = obj.select.lng
              if (obj.select.ins) {
                obj.select.ins.latitude = obj.select.lat
                obj.select.ins.longitude = obj.select.lng
              }
            } else {
              this.isSelectVirtualVehicle = false
              this.selectVirtualObj = null
              this.$store.dispatch('setVirtual', {
                isSelectVirtualVehicle: false,
                selectVirtualObj: null
              })
            }
          }
          console.log('loadVehiclesMap ids:', wIds)
          this.$store
            .dispatch('websocketSubSend', {
              ids: wIds,
              type: 'void',
              payload: {
                // 必需订阅
                mustSubs: true,
                type: 'all',
                data: obj
              }
            })
            .then(() => {})
            .finally(() => {})
        })
      }
    },
    handleTabChange(activeKey) {
      this.currentActiveTabKey = activeKey
      if (activeKey === '1' || activeKey === '2') {
        this.closeOtherModal()
      }
      if (activeKey === '2') {
        // 历史轨迹地图
        this.mapPageParams.mapDataSource = 'track'
        this.mapPageParams.showHeader = true
        this.mapPageParams.showMapTrackDetailButton = true
      } else {
        // 实时状态地图
        this.mapPageParams.mapDataSource = 'real'
        this.mapPageParams.showHeader = false
        this.mapPageParams.showMapTrackDetailButton = false
        this.mapPageParams.showMapTrackDetail = false
      }
      if (activeKey === '1') {
        this.mapPageParams.historyTrackPointsArray = []
        this.handleMapTrackPlayStop()
      }
      if (this.currentSelectedId) {
        if (activeKey === '5') {
          this.listEventRecord()
        } else if (activeKey === '6') {
          this.listEventRecord('4')
        }
      }
    },
    handleCloseLeftModal() {
      this.showLeftModal = false
    },
    initVehicleTree() {
      getVehicleTree().then((res) => {
        if (res.data) {
          this.allTreeData = res.data
          const data = JSON.parse(JSON.stringify(this.allTreeData))
          this.initTreeData(data)
          // 初始化所有车辆都不在线
          this.vehicleOnlineMap.clear()
          this.initTreeData(data, 0)
          console.log('vehicleOnlineMap', this.vehicleOnlineMap)
          this.treeData = data
        }
      })
    },
    initTreeData(data, online) {
      if (!data) {
        return
      }
      data.forEach((p) => {
        if (p.id.indexOf('t-1') >= 0) {
          p.online = online
          this.vehicleOnlineMap.set(p.id, online)
        }
        if (p.children) {
          this.initTreeData(p.children, online)
        }
      })
    },
    // 点击选中设备，当前选中Id，当前打勾设备列表
    handleSelectVehicle(selectedId, checkedList, isSameList) {
      console.log('handleSelectVehicle', checkedList, ';id:', selectedId, ';isSameList', isSameList)
      // 手动控制是否显示弹窗信息
      this.currentSelectedId = selectedId
      this.$refs.monitorRef.mapShowObjectInfo(selectedId, isSameList)
      // 先判断打勾列表是否发生变化，如果不变，则不重新加载后台数据
      if (this.currentCheckedList.toString() === checkedList.toString()) {
        // 检查触发时机 todo...
        // 通过ws请求数据
        this.subscribeOneVehicle()
        // console.log('打勾列表不变')
        this.toSelectObject()
        return
      }
      this.currentCheckedList = [...checkedList]
    },
    // 订阅某辆车
    subscribeOneVehicle() {
      // todo...
      if (!this.recentObjectPointsObj.data) {
        // 没有选择车辆时
        return
      }
      const currentSelectedId = this.bindVehicleIdMap[this.currentSelectedId] || this.currentSelectedId
      const obj = this.recentObjectPointsObj.data.find((p) => p.vehicleId === currentSelectedId)
      if (obj && !obj.withAllInfo) {
        this.subscribeManyVehicle([currentSelectedId])
      }
    },
    getVirtualInfo(id) {
      return new Promise((resolve, reject) => {
        getOneVehicleInfo({
          vehicleIds: id
        }).then((res) => {
          resolve(res.data[0])
        })
      })
    },
    // 订阅多辆车
    subscribeManyVehicle(ids, callback) {
      this.mapPageParams.loadingWebsocket = true
      this.isGettingVehicleInfo = true
      console.log(`http获取选中车辆全量数据,vehicleIds=${ids},allInfo`, this.allInfo)
      let virtualObj = null
      for (const item of this.allInfo) {
        if (item.vehicleId === ids[0]) {
          if (item.virtualVehicle === 1) {
            // 判断ids数组是否含有该vehicleId
            this.isSelectVirtualVehicle = true
            this.bindVehicleIdMap[item.vehicleId] = item.bindVehicleId
            item.withAllInfo = true
            virtualObj = item
            break
          } else {
            virtualObj = null
            this.isSelectVirtualVehicle = false
          }
        }
        break
      }

      getOneVehicleInfo({
        vehicleIds: ids.join(',')
      }).then((res) => {
        this.mapPageParams.loadingWebsocket = false
        this.isGettingVehicleInfo = false
        console.log(`http获取所有节点数据成功`)
        this.$store.dispatch('setVirtual', {
          isSelectVirtualVehicle: true,
          selectVirtualObj: virtualObj,
          trueVehicleData: res.data[0]
        })
        this.$store.dispatch('websocketSubSend', {
          payload: {
            // 不需要订阅
            mustSubs: false,
            type: 'manyVehicle',
            data: res.data
          }
        })
        if (callback) {
          callback(res.data)
        }
      })
    },
    // 选中的对象Id是否发生变化
    isChangedObjectIds(checkedList) {
      const newArr = checkedList.sort()
      const oldArr = this.currentCheckedList.sort()
      return newArr.toString() === oldArr.toString()
    },
    // 选中物体
    toSelectObject() {
      // 增加处理在线与离线
      const data = this.recentObjectPointsObj.data
      if (data) {
        console.log('toSelectObject:', data)
        const obj = data.find((p) => p.vehicleId === this.currentSelectedId)
        if (obj && this.objectInfo && obj.vehicleId === this.objectInfo.vehicleId && this.objectInfo.withAllInfo) {
          // 如果车辆Id相同，则直接更改对象属性值
          this.objectInfo = Object.assign(this.objectInfo, obj, {
            inc: this.objectInfo.inc ? ++this.objectInfo.inc : 1
          })
        } else {
          if (obj) {
            obj.inc = 1
          }
          this.objectInfo = obj
        }
        // 内存泄漏2：每次新对象赋值，会导致内存泄漏 2022-01-13
        // this.objectInfo = data.find((p) => p.vehicleId === this.currentSelectedId)
      }
      if (this.mapPageParams.mapDataSource === 'track') {
        this.mapPageParams.currentTrackObject = this.objectInfo
      }
    },
    setTrackObjectInfo() {
      // 增加处理在线与离线
      const data = this.recentObjectPointsObj.data
      if (data) {
        const currentTrackObject = this.mapPageParams.currentTrackObject
        console.log('setTrackObjectInfo:', currentTrackObject)
        const obj = data.find((p) => p.vehicleId === this.currentSelectedId)
        if (
          obj &&
          currentTrackObject &&
          obj.vehicleId === currentTrackObject.vehicleId &&
          currentTrackObject.withAllInfo
        ) {
          this.mapPageParams.currentTrackObject = Object.assign(currentTrackObject, obj)
        } else {
          if (this.currentSelectedId) {
            if (obj) {
              this.mapPageParams.currentTrackObject = obj
            }
          } else {
            this.mapPageParams.currentTrackObject = obj
          }
        }

        if (!this.objectInfo || !this.objectInfo.withAllInfo) {
          this.objectInfo = this.mapPageParams.currentTrackObject
        }
      }
    },
    // 打勾选择设备，当前打勾的设备列表，当前选中的设备Id
    handleCheckVehicle(checkedList, selectedId, isSameList) {
      console.log('handleCheckVehicle', checkedList, ';id:', selectedId, ';isSameList', isSameList)
      this.currentCheckedList = checkedList
      this.currentSelectedId = selectedId
      this.$refs.monitorRef.mapShowObjectInfo(selectedId, isSameList)
      this.toSelectObject()
    },
    handleMapPageClick(eventName, eventArgs) {
      console.log('handleMapPageClick eventArgs', eventArgs)
      if (eventName === 'showLeftModal') {
        this.showLeftModal = eventArgs
      } else if (eventName === 'showMapTrackDetail') {
        this.mapPageParams.showMapTrackDetail = eventArgs
      } else if (eventName === 'selectObjectInfo') {
        // 地图上选中物体
        this.objectInfo = { ...eventArgs, inc: 1 }
        this.currentSelectedId = eventArgs.vehicleId
        this.$refs.monitorRef.mapShowObjectInfo(eventArgs, true)
        this.subscribeOneVehicle()
      } else if (eventName === 'setMapObj') {
        this.MapObj = eventArgs
      }
    },
    // 地图上方工具栏点击事件
    handleMapToolClick(eventName, eventArgs) {
      // 显示地图轨迹明细
      if (eventName === 'showMapTrackDetail') {
        this.mapPageParams.showMapTrackDetail = eventArgs
      } else if (eventName === 'refreshData') {
      }
    },
    // 车辆功能区回调事件
    handleVehicleFuncEvent(eventName, eventArgs, callback) {
      // 路线查看
      if (eventName === 'viewRoute') {
        if (eventArgs && eventArgs.length > 0) {
          this.mapPageParams.laneDataArray = eventArgs
        }
      } else if (eventName === 'setCurrentVehicleId') {
        console.log('handleVehicleFuncEvent')
        this.currentSelectedId = eventArgs
        this.$refs.monitorRef.mapShowObjectInfo(eventArgs, true)
        this.toSelectObject()
        callback && callback()
      }
    },
    handleHistoryTrackSearch(eventArgs) {
      console.log('handleHistoryTrackSearch', eventArgs)
      if (!this.currentSelectedId) {
        this.$warningEx('请先选中一辆车！')
        return
      }
      // 暂停轨迹播放
      this.handleMapTrackPlayStop()
      const params = {}
      params.startTime = eventArgs.startTime
      params.endTime = eventArgs.endTime
      // 校验只能查看2天内的数据
      params.vehicleId = this.bindVehicleIdMap[this.currentSelectedId] || this.currentSelectedId
      getHistoricalTrack(params).then((res) => {
        console.log('getHistoricalTrack', res)
        const data = res.data
        if (data.length === 0) {
          this.$warningEx('查询不到数据！')
        }
        this.mapPageParams.showMapTrackDetailButton = true
        // 由于车端轨迹点位，status=2时方向不正确，暂时取消使用status=2时的方向
        const newData = data.map((p) => Object.assign(p, { rotation: p.status === 2 ? undefined : p.heading }))
        // 丢掉定位初始化点 mode = 0
        this.mapPageParams.historyTrackPointsArray = newData.filter((p) => {
          // 如果存在mode点位，则：
          if (p.mode !== undefined && p.mode !== null) {
            return p.mode > 0
          } else {
            return p
          }
        })
        this.mapPageParams.currentTrackObject = this.objectInfo
      })
    },
    // 轨迹播放停止
    handleMapTrackPlayStop() {
      this.$refs.monitorRef.mapTrackPlayStop()
    },
    getData() {
      this.getDicts('iot_task_state').then((response) => {
        this.taskStatusOptions = response.data
      })
      this.getDicts('vehicle_event_type').then((response) => {
        this.eventOptions = response.data
      })
    },
    toHistoryTrack(item) {
      // 跳转对应历史轨迹
      const dateStr = item.happenTime
      const timeRangBase = 60 * 1000
      const starDateStr = dateStr - timeRangBase
      const endDateSte = dateStr + timeRangBase
      const starDate = this.parseDateTime(new Date(starDateStr), 'yyyy-MM-dd HH:mm:ss')
      const endDate = this.parseDateTime(new Date(endDateSte), 'yyyy-MM-dd HH:mm:ss')
      this.timeRange = [starDate, endDate]
      this.activeKey = '2'
    },

    listEventRecord(type) {
      this.loading = true
      listEventRecord({ pageNum: 1, pageSize: 5, type, vehicleId: this.currentSelectedId }).then((response) => {
        this.vehicleEventlist = response.rows
        this.loading = false
      })
    }
  }
}
</script>
